import Swiper, { Navigation, Thumbs} from 'swiper';
import 'swiper/css';
import $ from 'jquery';
import noUiSlider from 'nouislider';
import { Tooltip, Modal, Popover, Collapse } from 'bootstrap';
import './modal';
import select2 from 'select2';
import axios from 'axios';

window.jQuery = window.$ = $;

document.addEventListener('DOMContentLoaded', function () {

    /** Мобильное меню */
    $('.js-mobile-menu').on('click', function () {
        document.body.classList.toggle('overflow-hidden');
        $('.navbar-slide-menu').toggleClass('navbar-slide-menu-open');
    });

    /** Бренды */
    $('.js-brands-toggle').on('click', function () {
        $('#brands').toggleClass('d-none');
    });

    /** модальное окно все Автосалоны */
    $('.js-avtosaloni-toggle').on('click', function () {
        $('#avtosaloni').toggleClass('d-none');
    });

    // end

    $('[data-toggle="tooltip"]').tooltip();

    new Swiper(".swiper", {
        cssMode: false,
        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
        },
        mousewheel: true,
        keyboard: true,
        modules: [Navigation],
    });

    if (window.innerWidth <= 768) {
        $('.carcard-swiper > .swiper-wrapper > .swiper-slide')
            .removeClass('swiper-slide')
            .addClass('swiper-slide-disabled');
        $('.carcard-swiper > .swiper-wrapper')
            .removeClass('swiper-wrapper')
            .addClass('swiper-wrapper-disabled');
    } else {
        new Swiper(".carcard-swiper", {
            slidesPerView: "auto",
            spaceBetween: 30,
            cssMode: false,
            navigation: {
                nextEl: ".carcard-swiper-next",
                prevEl: ".carcard-swiper-prev",
            },
            allowTouchMove: false,
            mousewheel: true,
            keyboard: true,
            modules: [Navigation]
        });
    }

    if (window.innerWidth < 768) {
        new Swiper('.swiper-propositions', {
            slidesPerView: "auto",
            freeMode: true,
        });
    }

    new Swiper('.swiper-free', {
        slidesPerView: "auto",
        freeMode: true,
    });

    new Swiper('.swiper-free-between', {
        slidesPerView: "auto",
        freeMode: true,
        spaceBetween: 8,
        centeredSlidesBounds: true
    });

    new Swiper('#brands', {
        slidesPerView: 'auto',
        freeMode: true,
        spaceBetween: 6,
        wrapperClass: 'search-brands-logos',
        slideClass: 'search-brands-card',
        slidesOffsetBefore: 20,
        slidesOffsetAfter: 20,
        breakpoints: {
            992: {
                slidesOffsetBefore: 0,
                slidesOffsetAfter: 0,
                spaceBetween: 0,
                freeMode: false,
                autoplay: false,
            }
        }
    });

    new Swiper(".car-slider", {
        spaceBetween: 10,
        modules: [Thumbs],
        thumbs: {
            swiper: new Swiper(".car-slider-gallery", {
                spaceBetween: 10,
                slidesPerView: 4,
                freeMode: true,
                watchSlidesProgress: true,
                modules: [Navigation],
                navigation: {
                    nextEl: ".car-slider-gallery-next",
                    prevEl: ".car-slider-gallery-prev",
                },
            }),
        },
    });

    new Swiper('.feedback-swiper', {
        spaceBetween: 15,
        freeMode: true,
        slidesPerView: 1.2,
        slidesOffsetBefore: 15,
        slidesOffsetAfter: 15,
        breakpoints: {
            992: {
                slidesPerView: 3,
                slidesOffsetBefore: 0,
                slidesOffsetAfter: 0,
            }
        }
    });

    $('.nouislider').each(function () {
        let $this = $(this);
        let data = {
            start: $this.data('start'),
            step: $this.data('step'),
            connect: $this.data('connect'),
            range: $this.data('range').split(',').reduce(
                (min, max) => ({min: parseFloat(min), max: parseFloat(max)})
            ),
        };
        data = Object.fromEntries(Object.entries(data).filter(([_, v]) => !!v));
        noUiSlider.create(this, data).on('update', function (value) {
            let selector = $(this.target).data('target');
            let $input = $(selector);
            $input.val(parseInt(value));
            $input.trigger('paste');
        });

        let that = this;
        $($this.data('target')).on('change', function () {
            if (parseInt(that.noUiSlider.get()) != parseInt(this.value.replace(/\D/g, ''))) {
                that.noUiSlider.set(this.value);
            }
        });
    });

    $('.js-number-format').on('keyup paste', function (event) {
        if (this.value != '') {
            this.value = parseInt(this.value.replace(/\D/g, '')).toLocaleString();
        }
    });

    $('.modal-close').on('click', function () {
        $(this).closest('.modal.open').removeClass('open');
    });

    $(document).on('click', '[data-modal]', function () {
        let id = $(this).data('modal');
        $(id).toggleClass('open');
    });

    $('.select2').select2({
        allowClear: true,
        placeholder: 'Поиск',
    });
});

// new scripts
function hideRightBrandsImg() {
    document.getElementById('rightBrandsImg').style.display="none";
}

window.changeSubdomain = function changeSubdomain(sub) {
    let parts = window.location.hostname.split('.');
    if (parts.length == 2 && !sub || parts.length > 2 && parts[0] == sub) {
        return;
    }

    let domain = parts.slice(-2).join('.');

    window.location.hostname = (sub == null) ? domain : sub + '.' + domain;
};

window.toggleSubdomainDropdown = function toggleSubdomainDropdown() {
    let c = document.querySelector('#city-dropdown').classList;
    if (c.contains('d-none')) {
        setTimeout(
            () => document.addEventListener('click', () => c.add('d-none'), {once: true}),
            100
        );
        c.remove('d-none');
    }
};

window.copyThisElementContent = function copyThisElementContent(_this) {
    if (!navigator.clipboard) {
        var textArea = document.createElement("textarea");
        textArea.value = _this.innerText;
        textArea.style.top = 0;
        textArea.style.left = 0;
        textArea.style.width = 0;
        textArea.style.height = 0;
        textArea.style.opacity = 0;
        textArea.style.position = 'fixed';
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
        try {
            document.execCommand('copy');
        } catch(e) {
            alert('Ваш браузер не позволяет скопировать это. Попробуйте скопировать вручную.');
            window.copyThisElementContent = function () {};
        }
        textArea.remove();
        return;
    }

    navigator.clipboard.writeText(_this.innerText).then(function() {}, function(err) {
        alert('Ваш браузер не позволяет скопировать это. Попробуйте скопировать вручную.');
        window.copyThisElementContent = function () {};
    });
};

document.addEventListener('DOMContentLoaded', function () {
    let stickyPrice = document.querySelector('#sticky-price');
    let carPrice = document.querySelector('#car-price');
    let isVisible = false;

    let windowScroll = function (event) {
        let mustBeVisible = (carPrice.getBoundingClientRect().bottom - 50) < 0;
        if (isVisible != mustBeVisible) {
            isVisible = mustBeVisible;
            if (isVisible)
                stickyPrice.classList.add('car-sticky-price_wrapper-visible');
            else
                stickyPrice.classList.remove('car-sticky-price_wrapper-visible');
        }
    };

    if (stickyPrice && carPrice) {
        windowScroll();
        window.addEventListener('scroll', windowScroll, { passive: true });
    }
});

document.addEventListener('DOMContentLoaded', function () {
    let price = document.querySelector('#section-car-price');
    let detail = document.querySelector('#modal-price-detail');
    detail && detail.addEventListener('show.bs.modal', event => {
        let pos = price.getBoundingClientRect();
        let modal = detail.querySelector('.modal-dialog');

        modal.style.top = pos.top + 'px';
        modal.style.left = pos.left + 'px';
        modal.style.width = pos.width + 'px';
        modal.style.height = pos.height + 'px';
    });
});

window.setSearchParam = function (param, value) {
    let url = new URL(window.location.href);
    url.searchParams.set(param, value);
    window.location.href = url.href;
}

window.sendAjaxForm = function(event) {
    event.preventDefault();
    let f = event.target;

    if (! f.checkValidity()) {
        f.reportValidity();
        console.log('Not valid');
        return false;
    }

    f.classList.add('order-form--loading');

    let formData = new FormData(f);
    let formObj = Object.fromEntries(formData.entries());
    let disabled = [];
    for (let element of f.elements) {
        if (! element.getAttribute('disabled')) {
            element.setAttribute('disabled', '');
            disabled.push(element);
        }
    }

    axios
        .post('/api/internal/order', formObj)
        .then(() => {
            let $modal = $('#modal-thanks');
            $modal.show();
            setTimeout(() => $modal.hide(), 10000);
        })
        .catch((error) => {
            alert('Ошибка отправки. Пожалуйста, попробуйте позже или свяжитесь с нами по телефону.');
            console.error(error);
        })
        .then(() => {
            f.classList.remove('order-form--loading');
            f.reset();
            for (let element of disabled) {
                element.removeAttribute('disabled');
            }
        });
};
